import * as SVG from "../../../common/Icons";

import {
  showWalletConnectModalDesk,
  showWalletConnectModalMobile,
  walletSignMessage,
  walletAppName,
  setIsWalletConnected,
} from "../../../store/slices/wallet";
import {
  ConnectWalletButton,
  ConnectWalletList,
  useCardano,
} from "@cardano-foundation/cardano-connect-with-wallet";
import { toastNotification } from "../../ToastNTF";
import CopyButton from "../../../pages/portfolio/CopyButtonWallet";
import { useEffect } from "react";
import axios from "axios";
import { ip } from "../../../baseurl/baseurl";
import { useWalletDisconnect } from "../../../store/WalletDisconnection";
import React, { useState } from "react";
import { Lucid } from "lucid-cardano";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import styles from "./WalletConnectModal.module.css";
import { FaCopy } from "react-icons/fa"; // Import the copy icon

const allWallets = [
  {
    id: 1,
    name: "Nami",
    img: "/nami.svg",
  },
  {
    id: 2,
    name: "Eternl",
    img: "/eternl.svg",
  },
  {
    id: 3,
    name: "Flint Wallet",
    img: "/flint.svg",
  },
  {
    id: 4,
    name: "Lace",
    img: "/lace.svg",
  },
  {
    id: 5,
    name: "NuFi",
    img: "/nufi.svg",
  },
  {
    id: 6,
    name: "VESPR",
    img: "https://play-lh.googleusercontent.com/peLer_G97zRfNSQvyGomKM0ZaBq9awWoru9yA6ezatTgr-4MCmsiUeothYh7qxw-AphX=w240-h480-rw",
  },
];

const WalletConnectModalDesk = () => {
  const [walletApi, setWalletApi] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [availableWallets, setAvailableWallets] = useState(allWallets);
  const dispatch = useDispatch();
  const disconnectWallet = useWalletDisconnect();

  const isWalletConnected = useSelector(
    (state) => state.wallet.isWalletConnected
  );

  useEffect(() => {
    const savedWalletAddress = localStorage.getItem("wallet_address");
    if (savedWalletAddress) {
      setWalletAddress(savedWalletAddress);
      setIsConnected(true);
    }

    // Function to update available wallets based on screen size
    const updateAvailableWallets = () => {
      if (window.innerWidth <= 768) {
        // Mobile breakpoint
        setAvailableWallets([allWallets[2], allWallets[5]]); // Only Nami and Flint
      } else {
        setAvailableWallets(allWallets); // All wallets for larger screens
      }
    };

    // Set available wallets on mount and resize
    updateAvailableWallets();
    window.addEventListener("resize", updateAvailableWallets);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", updateAvailableWallets);
    };
  }, []);

  const connectWallet = async (walletKey) => {
    try {
      const wallet = window.cardano?.[walletKey];

      if (wallet) {
        const api = await wallet.enable();
        setWalletApi(api);
        // Check if the wallet is connected to the testnet
        const network = await api.getNetworkId(); // Assuming getNetworkId() returns the network ID
        console.log("network", network);
        if (network === 0) {
          dispatch(setIsWalletConnected(false));
          disconnectWallet();
          dispatch(showWalletConnectModalDesk());
          setIsConnected(false);
          dispatch(walletSignMessage(null));
          setWalletAddress("");
          toast.error("Please shift to Mainnet before connecting.");
          return; // Exit the function if the user is on testnet
        }
        const lucid = await Lucid.new(undefined, "Mainnet");
        lucid.selectWallet(api);

        const address = await lucid.wallet.address();
        setWalletAddress(address);
        localStorage.setItem("wallet_address", address);
        try {
          const response = await axios.post(
            `${ip}/api/pro/check_pro_authentication`,
            {
              walletAddress: address, // Assuming you're interested in the first address
              // walletAddress:
              // "addr1q9v6mz6zt4gynyu5k0kmjc8hu2h2u45z3hrgj6vzszx45sw8mq03lrnqmq6mcpxy6u4g43vqch4tah6d9v5st89e2cus3tncgn",
            }
          );
          console.log("authenticated_user_pro", response.data);
          localStorage.setItem(
            "authenticated_user_pro",
            JSON.stringify(response?.data)
          );
          // toast.success("User authenticated and data stored");
        } catch (error) {
          console.error("Error authenticating user:", error);
          // toast.error("Failed to authenticate user");
        }
        dispatch(setIsWalletConnected(true));

        setIsConnected(true);
        localStorage.setItem(
          "isWalletConnected",
          JSON.stringify({
            walletKey,
            isConnected: true,
          })
        );
        // Create a custom event name
        const walletConnectionChangedEvent = new Event(
          "walletConnectionChanged"
        );
        window.dispatchEvent(walletConnectionChangedEvent);

        dispatch(showWalletConnectModalDesk());
        setTimeout(() => {
          toast.success(`Wallet connected successfully`);
        }, 300);
      } else {
        alert("Wallet not found");
      }
    } catch (error) {
      console.error("Error connecting to wallet:", error);
    }
  };

  const disconnectWalletHandler = () => {
    dispatch(setIsWalletConnected(false));
    disconnectWallet();

    setIsConnected(false);
    dispatch(walletSignMessage(null));
    setWalletAddress("");

    toast.success("Wallet disconnected");
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Wallet address copied to clipboard");
  };

  const truncateAddress = (address) => {
    if (!address) return "";
    const start = address.slice(0, 6);
    const end = address.slice(-4);
    return `${start}...${end}`;
  };

  useEffect(() => {
    const savedWalletAddress = localStorage.getItem("wallet_address");
    if (savedWalletAddress) {
      setWalletAddress(savedWalletAddress);
      setIsConnected(true);
    }

    // Function to update available wallets based on screen size
    const updateAvailableWallets = () => {
      if (window.innerWidth <= 768) {
        // Mobile breakpoint
        setAvailableWallets([allWallets[2], allWallets[5]]); // Only Nami and Flint
      } else {
        setAvailableWallets(allWallets); // All wallets for larger screens
      }
    };

    // Set available wallets on mount and resize
    updateAvailableWallets();
    window.addEventListener("resize", updateAvailableWallets);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", updateAvailableWallets);
    };
  }, []);
  return (
    <div className={styles.modalContainer}>
      {isModalOpen && (
        <div className={styles.fixedModal}>
          <div className={styles.modalContent}>
            {isConnected ? (
              <>
                <h2 className={styles.modalHeader}>Connected Wallet</h2>
                <div className="flex justify-center items-center space-x-3">
                  <p className={styles.modalText}>
                    Wallet Address:{" "}
                    <span className="text-green-600 font-semibold">
                      {truncateAddress(walletAddress)}
                    </span>
                  </p>
                  <FaCopy
                    className={styles.copyIcon}
                    onClick={() => copyToClipboard(walletAddress)}
                    title="Copy Address"
                  />
                </div>
                <button
                  onClick={disconnectWalletHandler}
                  className={`${styles.button} ${styles.disconnectButton}`}
                >
                  Disconnect Wallet
                </button>
                <button
                  onClick={() => dispatch(showWalletConnectModalDesk())}
                  className={`${styles.button} ${styles.closeButton}`}
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <h2 className={`${styles.modalHeader} mb-6`}>
                  Select a Wallet
                </h2>
                <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {availableWallets.map((wallet) => (
                    <li key={wallet.id} className="text-center">
                      <button
                        onClick={() => connectWallet(wallet.name.toLowerCase())}
                        className={styles.walletButton}
                      >
                        <img
                          src={wallet.img}
                          alt={`${wallet.name} logo`}
                          className="h-8 w-8 mr-3"
                        />
                        {wallet.name}
                      </button>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => dispatch(showWalletConnectModalDesk())}
                  className={`${styles.button} ${styles.closeButton} mt-6`}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {walletAddress && (
        <p className="mt-4 text-lg font-medium text-center text-gray-700">
          Connected Wallet Address:{" "}
          <span className="text-green-500 font-semibold">
            {truncateAddress(walletAddress)}
          </span>
          <FaCopy
            className={`${styles.copyIcon} inline-block ml-2`}
            onClick={() => copyToClipboard(walletAddress)}
            title="Copy Address"
          />
        </p>
      )}
    </div>
  );
};

export default WalletConnectModalDesk;
