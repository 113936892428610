import * as IMG from "../../common/IMG/Images";

export const dataVolume = [
  {
    name: "Minswap",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      17423, 14210, 16764, 17879, 13478, 18373, 13891, 12171, 15520, 17269,
      13724, 16707, 18188, 13879, 15626, 13635, 16177, 13156, 18793, 12598,
      18200, 15316, 17552, 11874, 19771, 15038, 16838, 12029, 19793, 16117,
      12879, 17478, 11373, 19891, 15171, 12520, 16269, 19724, 13707, 15188
    ]
  },
  {
    name: "WingRiders",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      1362, 2254, 1962, 1336, 586, 1069, 2194, 1629, 2173, 2031, 1757, 862,
      2446, 910, 2430, 2300, 805, 1835, 1684, 2197, 2792, 886, 2472, 1546, 2164,
      2323, 2435, 1268, 2368, 2158, 2430, 2300, 805, 1835, 1684, 2197, 2792,
      886, 2472, 1546
    ]
  },
  {
    name: "MuesliSwap",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      1145, 1214, 975, 2266, 1768, 2341, 747, 1282, 1780, 1766, 2115, 1720,
      1057, 2000, 1716, 2253, 619, 1626, 1209, 1786, 1123, 1461, 672, 1335, 960,
      1871, 2305, 1231, 2005, 908, 2254, 1962, 1336, 586, 1069, 2194, 1629,
      2173, 2031, 1757
    ]
  },
  {
    name: "VyFinance",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      2361, 979, 2430, 1768, 1913, 2342, 1868, 1319, 1038, 2139, 1691, 935,
      2262, 1580, 692, 1559, 1344, 1442, 1593, 1889, 2074, 744, 2487, 823, 2252,
      2317, 2139, 1818, 2256, 1769, 975, 2266, 1768, 2341, 747, 1282, 1780,
      1766, 2115, 1720
    ]
  },
  {
    name: "Spectrum",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      2968, 1371, 1381, 1060, 1327, 934, 1779, 1361, 878, 1055, 1737, 2380, 875,
      2408, 1066, 1802, 1442, 1567, 1552, 1742, 814, 1328, 883, 1811, 2322,
      1743, 700, 2131, 1473, 957, 1381, 1060, 1327, 934, 1779, 1361, 878, 1055,
      1737, 2380
    ]
  },
  {
    name: "SundaeSwap",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      1316, 1845, 2057, 1479, 1859, 1015, 1569, 1448, 1354, 1007, 799, 1748,
      1454, 1968, 1129, 1196, 2158, 540, 1482, 880, 2140, 2082, 708, 2032, 554,
      1365, 2121, 1639, 2430, 2440, 2057, 1479, 1859, 1015, 1569, 1448, 1354,
      1007, 799, 1748
    ]
  }
];

export const dataTrades = [
  {
    name: "Minswap",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      19423, 14210, 18764, 18759, 17478, 13373, 16891, 17171, 11520, 12269,
      12724, 17707, 12188, 17879, 12626, 17635, 11177, 12156, 15793, 16598,
      14200, 15316, 13552, 17874, 13771, 13038, 18838, 17029, 13793, 12117,
      13879, 15478, 15373, 17891, 13171, 18520, 13269, 17724, 11707, 17188
    ]
  },
  {
    name: "WingRiders",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      1145, 1214, 975, 2266, 1768, 2341, 747, 1282, 1780, 1766, 2115, 1720,
      1057, 2000, 1716, 2253, 619, 1626, 1209, 1786, 1123, 1461, 672, 1335, 960,
      1871, 2305, 1231, 2005, 908, 2254, 1962, 1336, 586, 1069, 2194, 1629,
      2173, 2031, 1757
    ]
  },
  {
    name: "MuesliSwap",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      1362, 2254, 1962, 1336, 586, 1069, 2194, 1629, 2173, 2031, 1757, 862,
      2446, 910, 2430, 2300, 805, 1835, 1684, 2197, 2792, 886, 2472, 1546, 2164,
      2323, 2435, 1268, 2368, 2158, 2430, 2300, 805, 1835, 1684, 2197, 2792,
      886, 2472, 1546
    ]
  },
  {
    name: "VyFinance",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      2968, 1371, 1381, 1060, 1327, 934, 1779, 1361, 878, 1055, 1737, 2380, 875,
      2408, 1066, 1802, 1442, 1567, 1552, 1742, 814, 1328, 883, 1811, 2322,
      1743, 700, 2131, 1473, 957, 1381, 1060, 1327, 934, 1779, 1361, 878, 1055,
      1737, 2380
    ]
  },
  {
    name: "Spectrum",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      1316, 1845, 2057, 1479, 1859, 1015, 1569, 1448, 1354, 1007, 799, 1748,
      1454, 1968, 1129, 1196, 2158, 540, 1482, 880, 2140, 2082, 708, 2032, 554,
      1365, 2121, 1639, 2430, 2440, 2057, 1479, 1859, 1015, 1569, 1448, 1354,
      1007, 799, 1748
    ]
  },
  {
    name: "SundaeSwap",
    type: "bar",
    stack: "total",
    emphasis: {
      focus: "series"
    },
    data: [
      2361, 979, 2430, 1768, 1913, 2342, 1868, 1319, 1038, 2139, 1691, 935,
      2262, 1580, 692, 1559, 1344, 1442, 1593, 1889, 2074, 744, 2487, 823, 2252,
      2317, 2139, 1818, 2256, 1769, 975, 2266, 1768, 2341, 747, 1282, 1780,
      1766, 2115, 1720
    ]
  }
];

export const leaderboard = [
  {
    img: IMG.Minswap,
    name: "Minswap",
    DEX: 227255628,
    Trades: 167173,
    Users: 20526,
    DAU: 1991,
    Fees: 328968,
    TVL: 153677066,
    Market1: 57429173,
    Market2: 22781983,
    MCap: 0.37,
    Volume: 1.48
  },
  {
    img: IMG.Wingriders,
    name: "WingRiders",
    DEX: 21717650,
    Trades: 17598,
    Users: 4167,
    DAU: 271,
    Fees: 35196,
    TVL: 25584660,
    Market1: 7504716,
    Market2: 2977099,
    MCap: 0.29,
    Volume: 0.85
  },
  {
    img: IMG.Vyfi,
    name: "VyFinance",
    DEX: 18309982,
    Trades: 19709,
    Users: 4177,
    DAU: 306,
    Fees: 37447,
    TVL: 20144022,
    Market1: 8987943,
    Market2: 3565490,
    MCap: 0.45,
    Volume: 0.91
  },
  {
    img: IMG.Muesliswap,
    name: "MuesliSwap",
    DEX: 17936473,
    Trades: 12753,
    Users: 3317,
    DAU: 237,
    Fees: 13669,
    TVL: 36422117,
    Market1: 54124549,
    Market2: 21471049,
    MCap: 1.49,
    Volume: 0.49
  },
  {
    img: IMG.Spectrumfi,
    name: "Spectrum",
    DEX: 17538958,
    Trades: 19045,
    Users: 4255,
    DAU: 344,
    Fees: 44995,
    TVL: 12939031,
    Market1: 2884881,
    Market2: 1144424,
    MCap: 0.22,
    Volume: 1.36
  },
  {
    img: IMG.SundaeSwap,
    name: "SundaeSwap",
    DEX: 16476714,
    Trades: 15804,
    Users: 4592,
    DAU: 253,
    Fees: 39510,
    TVL: 22525818,
    Market1: 17391050,
    Market2: 6898978,
    MCap: 0.77,
    Volume: 0.73
  }
];

export const series = [
  {
    name: "Minswap",
    type: "line",
    data: [
      "43129.8700",
      "11287.1000",
      "68123.0600",
      "33873.0200",
      "14110.0600",
      "73451.5300",
      "39560.2500",
      "69953.8000",
      "10688.0200",
      "48113.8000",
      "33873.0200",
      "14110.0600"
    ],
    smooth: true,
    lineStyle: {
      width: 2
    },
    emphasis: {
      focus: "series"
    },
    showSymbol: false
  },
  {
    name: "WingRiders",
    type: "line",
    data: [
      "23129.8700",
      "51287.1000",
      "38123.0600",
      "73873.0200",
      "24110.0600",
      "43451.5300",
      "19560.2500",
      "59953.8000",
      "20688.0200",
      "78113.8000",
      "43451.5300",
      "19560.2500"
    ],
    smooth: true,
    lineStyle: {
      width: 2
    },
    emphasis: {
      focus: "series"
    },
    showSymbol: false
  },
  {
    name: "MuesliSwap",
    type: "line",
    data: [
      "13129.8700",
      "41287.1000",
      "78123.0600",
      "33873.0200",
      "24110.0600",
      "63451.5300",
      "19560.2500",
      "49953.8000",
      "70688.0200",
      "28113.8000",
      "13129.8700",
      "41287.1000"
    ],
    smooth: true,
    lineStyle: {
      width: 2
    },
    emphasis: {
      focus: "series"
    },
    showSymbol: false
  },
  {
    name: "VyFinance",
    type: "line",
    data: [
      "23129.8700",
      "51287.1000",
      "18123.0600",
      "83873.0200",
      "34110.0600",
      "63451.5300",
      "19560.2500",
      "59953.8000",
      "20688.0200",
      "78113.8000",
      "19560.2500",
      "59953.8000"
    ],
    smooth: true,
    lineStyle: {
      width: 2
    },
    emphasis: {
      focus: "series"
    },
    showSymbol: false
  },
  {
    name: "Spectrum",
    type: "line",
    data: [
      "465060.1600",
      "522378.2100",
      "348193.7500",
      "557336.6800",
      "570430.7300",
      "435428.7800",
      "581757.7500",
      "421526.3500",
      "521364.7200",
      "501346.1500",
      "570430.7300",
      "435428.7800"
    ],
    smooth: true,
    lineStyle: {
      width: 2
    },
    emphasis: {
      focus: "series"
    },
    showSymbol: false
  },
  {
    name: "SundaeSwap",
    type: "line",
    data: [
      "63129.8700",
      "21287.1000",
      "68123.0600",
      "63873.0200",
      "74110.0600",
      "23451.5300",
      "59560.2500",
      "19953.8000",
      "30688.0200",
      "68113.8000",
      "74110.0600",
      "23451.5300"
    ],
    smooth: true,
    lineStyle: {
      width: 2
    },
    emphasis: {
      focus: "series"
    },
    showSymbol: false
  }
];

export const leftWallet = [
  {
    name: "MuesliSwap",
    value: 8203
  },
  {
    name: "WingRiders",
    value: 9000
  },
  {
    name: "VyFinance",
    value: 9203
  },
  {
    name: "Spectrum",
    value: 10203
  },
  {
    name: "SundaeSwap",
    value: 11203
  },
  {
    name: "Minswap",
    value: 30203
  }
];

export const leftFee = [
  {
    name: "MuesliSwap",
    value: 3203
  },
  {
    name: "WingRiders",
    value: 4000
  },
  {
    name: "VyFinance",
    value: 5203
  },
  {
    name: "Spectrum",
    value: 6203
  },
  {
    name: "SundaeSwap",
    value: 7203
  },
  {
    name: "Minswap",
    value: 26203
  }
];
