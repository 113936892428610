const NftCrasher = () => {
  return (
    <>
      <div className="w-full">
        <iframe
          src="https://beta.crashr.io/"
          height="900rem"
          width="100%"
          title="uniswap"
        ></iframe>
      </div>
    </>
  );
};

export default NftCrasher;
