import React, { useEffect, useState } from "react";
import SimpleBarReact from "simplebar-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for styling
import Routers from "./routes/Router.js";
import Progress from "./components/AddedComponents/ProgressBar.jsx";
import axios from "axios";
import { maintainace } from "./baseurl/baseurl.js";
import { decryption } from "./functions/crypto.js";
import "./App.css"; // Your existing CSS

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    const checkMaintenance = async () => {
      try {
        const response = await axios.get(maintainace);
        console.log("response for maintainance iamsun", response);
        if (response.data) {
          const decryptedData = decryption(response.data);
          console.log("response for decryptedData iamsun", decryptedData);
          if (decryptedData.state) {
            // setIsMaintenance(true);
          }
        }
      } catch (error) {
        console.error("Error checking maintenance status:", error);
      }
    };

    checkMaintenance();
  }, []);

  if (isMaintenance) {
    window.location.href = "https://maintenance.riskwisepro.io/";
    return null;
  }

  return (
    <SimpleBarReact className="bgHome">
      <Routers />
      <Progress />
      <ToastContainer
        position="top-right" // You can change this to top-left, bottom-left, bottom-right, or bottom-center
        autoClose={3000} // Auto close duration in milliseconds
        hideProgressBar={false} // Whether to show the progress bar
        newestOnTop={false} // Newest toast on top
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" // You can also use "dark"
        toastClassName="toast-custom" // Apply custom class
      />
    </SimpleBarReact>
  );
}

export default App;
