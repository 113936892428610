import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { timestampToUTC } from "../../../../../functions/functions";
import * as IMG from "../../../../../common/IMG/Images";
import { getImage } from "../../../../../baseurl/baseurl";
import { useEffect } from "react";
import moment from "moment/moment";
import useAddressHandler from "../../../../../customHook/adressConvert";
import { setwalletprofilermenuitems } from "../../../../../store/slices/wallet";

const tableCustomStyles = {
  table: {
    style: {
      borderRadius: "18px 18px 0 0",
      backgroundColor: "#142028",
      overflowY: "auto",
      textOverflow: "ellipsis",
      height: "100%",
      width: "100%",
      borderBottom: "1px solid #56577A",
      whiteSpace: "nowrap",
      "@media (max-width: 820px)": {
        tableLayout: "fixed", // Apply fixed layout for small screens
        minWidth: "100%", // Ensure table takes full width on small screens
      },
    },
  },

  tableWrapper: {
    style: {
      display: "table",
      backgroundColor: "#142028",
      borderRadius: "18px 18px 0 0",
      padding: ".8rem",
      height: "79vh",
      overflowX: "auto",
      "@media (max-width: 820px)": {
        padding: ".4rem",
        height: "60vh",
      },
    },
  },

  headCells: {
    style: {
      backgroundColor: "#142028",
      fontSize: "1rem",
      color: "#A0AEC0",
      fontWeight: "bold",
      padding: "0 8px",
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "@media (max-width: 820px)": {
        fontSize: "0.6rem",
        padding: "0 4px",
        width: "50px !important", // Fixed width in pixels with !important for iPhone
      },
    },
  },

  cells: {
    style: {
      fontSize: "0.875rem",
      color: "white",
      fontWeight: "bold",
      padding: "8px",
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "@media (max-width: 820px)": {
        fontSize: "0.5rem",
        padding: "4px",
        width: "80px !important", // Fixed width in pixels with !important for iPhone
      },
    },
  },

  rows: {
    style: {
      backgroundColor: "#142028",
      color: "white",
      borderBottom: "1px solid #56577A",
      display: "flex",
      flexWrap: "wrap",
      "@media (max-width: 820px)": {
        // Responsive row layout on small screens
        // flexDirection: "column", // Stack rows if needed
      },
    },
  },

  // Other styles remain unchanged...
};



const TokenAssoc = () => {
  const { inputOutputData } = useSelector(
    (state) => state.walletProfilerReducer
  );
  const { isSearching, handleAddresses } = useAddressHandler();
  const dispatch = useDispatch();
  const handleSubmit = async (inputValue) => {
    if (inputValue?.length > 0) {
      handleAddresses(inputValue);
    } else {
      return null;
    }
  };

  const columns = [
    {
      name: "Address",
      // width: "130px",
      selector: (row) => (
        <span
          className={`${
            row?.stake?.length ? "hover:text-gray-500" : ""
          } w-full truncate`}
          onClick={() => handleSubmit(row?.stake)}
        >
          {row?.stake?.length
            ? row?.stake?.substring(0, 10) +
              "..." +
              row?.stake?.substring(row?.stake?.length - 4, row?.stake?.length)
            : "N/A"}
        </span>
      ),
    },

    {
      name: "InboundAmt",
      selector: (row) =>
        `₳ ${(
          row?.amount?.inputs[0]?.amount[0]?.quantity / 1000000
        )?.toFixed()}`,
      // maxWidth: "280px",
    },
    {
      name: "OutboundAmt",
      selector: (row) =>
        `₳ ${(
          row?.amount?.outputs[0]?.amount[0]?.quantity / 1000000
        )?.toFixed()}`,
    },
    {
      name: "Transaction",
      selector: (row) => (
        <div className="">
          {moment(timestampToUTC(row?.blockTime)).format("LLL")}
        </div>
      ),
      //   maxWidth: "300px",
    },
  ];

  return (
    <>
      <div className="pt-4 ">
        <DataTable
          columns={columns}
          data={inputOutputData}
          pagination={true}
          paginationPerPage={15}
          paginationRowsPerPageOptions={[10, 20, 30]}
          customStyles={tableCustomStyles}
          noDataComponent="No data found"
          pointerOnHover
          responsive
          fixedHeaderScrollHeight="300px"
          subHeaderWrap
        />
      </div>
    </>
  );
};

export default TokenAssoc;
