import React, { useEffect } from "react";
import { showToast } from "../../functions/showToast";

const BMS = () => {
  useEffect(() => {
    showToast();
  }, []);

  return (
    <div className="w-full">
      <iframe
        src="https://bms.staking.zip/"
        height="900rem"
        width="100%"
        allow="geolocation"
        title="jpg store"
      ></iframe>
    </div>
  );
};

export default BMS;
