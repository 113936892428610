const NftjpgStore = () => {
  return (
    <>
      <div className="w-full">
        <iframe
          src="https://www.jpg.store/"
          height="900rem"
          width="100%"
          title="uniswap"
        ></iframe>
      </div>
    </>
  );
};

export default NftjpgStore;
