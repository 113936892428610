import React from 'react'

function CardanoScanIFrame() {
  return (
    <>
        <div className="w-full">
        <iframe
          src="https://cardanoscan.io/"
          height="900rem"
          width="100%"
          title="uniswap"
        ></iframe>
      </div>
    </>
  )
}

export default CardanoScanIFrame
